<template>
  <div class="project-details">
    <el-breadcrumb separator="/" class="my-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/push/adaptationproject' }" v-if="$route.query.whichpath === 'push'">智能推送</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/policy' }" v-else>政策文库</el-breadcrumb-item>
      <el-breadcrumb-item>申报通知</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div :class="['zc', { sticky: !flag }]" ref="zc">
        <div>
          <div v-if="flag" class="zc-title" ref="demoCopy" onmouseover="this.style.color = '#156ED0'" onmouseout="this.style.color = 'black'">
            <span @click="handleCopy" class="copy-title">
              {{ tongzhiTitleData.title }}
            </span>
          </div>
          <div v-else class="sticky-title" ref="demoCopy" onmouseover="this.style.color" onmouseout="this.style.color">
            <span class="copy-title">
              {{ tongzhiTitleData.title }}
            </span>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="handleDeclare" :disabled="declareObj.disabled">
            {{ declareObj.title }}
          </el-button>
        </div>
      </div>
      <div class="content" style="margin-top: 50px">
        <div class="title-info">
          <div>
            <span>来源：{{ tongzhiTitleData.fw_department }}</span><span>发文日期：{{ tongzhiTitleData.dispatch_date }}</span><span>申报截止日期：{{ tongzhiTitleData.declare_date }}</span>
          </div>
          <div>
            <span @click="setSubscribe" style="cursor: pointer" :style="
                subscribe
                  ? 'color:#CACACA'
                  : 'color:#3048f0;border:1px solid #156ED0;padding:2px;border-radius:5px'
              " class="">
              <!-- <img :src="subscribeImg" alt=""> -->
              {{ subscribe ? "已订阅" : "+ 订阅" }}
            </span>
            <el-dropdown style="position: relation">
              <span style="cursor: pointer"><img src="../../../../assets/pc/images/policy/share.png" alt="" />分享</span>
              <el-dropdown-menu slot="dropdown" style="padding: 20px">
                <div class="share-content">
                  <!-- <div id="qrcode" ref="qrcode"></div>
                  <div class="" style="padding-top: 10px">扫码进入小程序</div> -->
                  <div>
                    <el-input type="text" class="input" v-model="linkShare" @click.native="handleShare"></el-input>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-row>
          <el-col :span="18">
            <el-radio-group v-model="radio1" @change="handleRadio">
              <el-radio-button v-for="(item, index) in pathList" :key="index" :label="item.path">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="6" style="margin-top: 30px">
            <!-- <keep-alive> -->
            <el-button v-show="
                $store.state.policy.preview !== '1' ||
                $route.query.preview !== '1'
              " type="primary" style="float: right" @click="handleReturn">返回上一级</el-button>
            <!-- </keep-alive> -->
          </el-col>
        </el-row>
        <!-- <component
        ref="isCom"
        :is="comName"
        :routeId="getIdFromRoute"
      ></component> -->
        <div class="modal">
          <router-view ref="routerCom" />
        </div>
      </div>
      <el-dialog class="shengbao-dialog" :title="dialogTitle" :visible.sync="shenbaoDialog" width="480px">
        <div v-if="userData.name">
          <div class="head">
            <img src="../../../../assets/pc/images/policy/shenbao.png" alt="" />
            <div>
              <div>请留下您的申报需求</div>
              <div>我们会在第一时间给您提供申报服务</div>
            </div>
          </div>
          <el-form :model="shenbaoForm" ref="shenbaoFormRef" :rules="shenbaoRules" label-position="top">
            <el-form-item prop="entityName" label="申报主体（企业名称）">
              <el-input v-model="shenbaoForm.entityName" placeholder="请输入企业主体的名称"></el-input>
            </el-form-item>
            <el-form-item required>
              <el-col :span="11" style="margin-right: 8%">
                <el-form-item prop="contantName" label="联系人">
                  <el-input v-model="shenbaoForm.contantName" placeholder="请输入联系人姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="contantPhone" label="电话">
                  <el-input v-model="shenbaoForm.contantPhone" placeholder="请输入联系人电话" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="shenbaoForm.remark" placeholder="请输入你的需求"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 8px;">
            <el-button type="primary" :disabled="disabledEvent" @click="shenbaoEvent">提交</el-button>
          </div>
        </div>
        <div v-else class="hasBindEntity">
          <div>
            <img src="@/assets/pc/images/push/subject.png" alt="" />
          </div>
          <div style="margin-top: 10px" class="information_color">
            请先绑定主体
          </div>
          <div style="margin: 10px 0" class="information_color">
            以便查询更多政策资讯
          </div>
          <div class="button" style="text-align: center; margin-top: 30px">
            <button class="goto_bind" @click="dialogVisible = true">
              立即绑定
            </button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title="绑定主体"
        :visible.sync="dialogVisible"
        width="500px">
        <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
          <el-form-item prop="name" label="主体名称">
            <el-autocomplete
                  class="inline-input"
                  v-model="form.name"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入机构执照名称"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  :debounce="0"
                  style="width:100%"
                  clearable
                >
              </el-autocomplete>
          </el-form-item>
          <el-form-item :label="form.label" v-if="form.type == '0'">
            <div class="credit_code">
              <div style="flex:1">{{form.code}}</div>
              <div class="div_code">
                <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
              </div>
              <div class="div_code">
                <el-input v-model="form.second_code"  maxlength="1" ref="inputRef2"></el-input>
              </div>
              <div class="div_code">
                <el-input v-model="form.third_code"  maxlength="1" ref="inputRef3"></el-input>
              </div>
              <div class="div_code">
                <el-input v-model="form.fourth_code"  maxlength="1" ref="inputRef4"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="form.label" v-else>
            <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
          </el-form-item>
        </el-form>
        <div class="bind_btn_box">
          <button class="bind_btn" @click="bindEntity">立即绑定</button>
        </div>
      </el-dialog>
      <permission v-if="dialogVisiblePermission" :dialogVisiblePermission="dialogVisiblePermission" @handleToggle="handleToggle" :typeDialog="typeDialog" @handlePermission="handlePermission">
      </permission>
    </div>
    <el-dialog
      :visible.sync="subscribeVisible"
      width="300px">
      <div class="subscrib_dialog">
        <div>
          <img src="../../../../assets/pc/images/policy/subscribe 2.png" alt="">
        </div>
        <div>
          订阅成功
        </div>
        <div>
          点击 
          <span style="color: #1f88f9;cursor: pointer;" @click="goToRecord">智能推送 - 我的订阅</span>
           查看订阅记录
        </div>
        <el-button 
          type="primary"
          @click="subscribeVisible = false"
          style="width: 70%;">
          我知道了
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/network";
import { setInfoData, getMemberId } from "@/assets/public/utils/token";
import { mapMutations, mapState } from "vuex";
import QRCode from "qrcodejs2";
import permission from "@/components/pc/permission.vue";

export default {
  components: {
    permission,
  },
  data() {
    return {
      flag: true,
      top: "",
      radio1: "/formaldetails/tongzhi",
      // radio1: 'TongZhi',
      shenbaoDialog: false,
      tongzhiTitleData: {},
      getIdFromRoute: "",
      shenbaoForm: {
        entityName: "",
        contantName: "",
        contantPhone: "",
        remark: "",
      },
      shenbaoRules: {
        entityName: [
          { required: true, message: "请输入企业主体的名称", trigger: "blur" },
        ],
        contantName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contantPhone: [
          {
            required: true,
            pattern: "^[1][3456789][0-9]{9}$",
            message: "请输入正确联系人电话",
            trigger: "blur",
          },
        ],
      },
      subscribe: false,
      subscribeImg: require("../../../../assets/pc/images/policy/subscribe.png"),
      linkShare: "",
      dialogVisiblePermission: false,
      typeDialog: "Vip",
      pathList: [
        {
          name: "申报通知",
          path: "/formaldetails/tongzhi",
          component: "TongZhi",
        },
        { name: "相关文件", path: "/formaldetails/wenji", component: "WenJi" },
        { name: "项目摘要", path: "/formaldetails/jiedu", component: "JieDu" },
        { name: "联系信息", path: "/formaldetails/xinxi", component: "XinXi" },
        {
          name: "获批名单",
          path: "/formaldetails/mingdan",
          component: "MingDan",
        },
      ],
      imageType: ["jpg", "png", "jpeg"],
      comName: "TongZhi", // 动态组件的组件名
      loading: null,
      preview: null,
      // 申报按钮的显示对象
      declareObj: {
        disabled: false,
        title: "我要申报",
      },
      disabledEvent: false,
      dialogVisible: false,
      userData: {},
      dialogTitle: '',
      form: {
        name: '',
        code: '',//信用编码前14位
        first_code: '',
        second_code: '',
        third_code: '',
        fourth_code: '',
        label: '统一社会信用代码',
        type: '1',
        codeInput: '',//自己输入信用编码
        creditCode: '',//天眼信用编码
        area: '',
        estiblishTime: '',
        regCapital: '',
        legalPersonName: ''
      },
      rule: {
        name: [
          { required: true, message: '请输入机构执照全称', trigger: 'blur' }
        ]
      },
      subscribeVisible: false
    };
  },
  beforeMount() {
    this.getDeclareLog().then(() => {
      setInfoData(request).then((res) => {
        // if (res === 'islogin') {
        //   this.linkShare = 'https://po-o.cn/#' + this.$route.fullPath;
        // } else {
        //   this.linkShare = 'https://po-o.cn/#' + '/login/loginbyverification';
        // }
        const txt =
          res === "islogin"
            ? this.$route.fullPath
            : "/login/loginbyverification";
        this.linkShare = process.env.VUE_APP_HTTP_URL + txt;
      });
    });
  },
  mounted() {
    this.preview = this.$route.query.preview;
    this.loading = this.$loading({
      target: "project-details",
      lock: true,
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.loadPageFunc();
    if (!localStorage.getItem("radioButtonPath")) {
      localStorage.setItem("radioButtonPath", this.radio1);
    }
    this.handleRoute();
    this.top = this.$refs.zc.offsetTop;
    addEventListener("scroll", this.onScroll, false);
    // new QRCode("qrcode", {
    //   width: 150,
    //   height: 150, // 高度
    //   text: `${process.env.VUE_APP_Policy_Code}/redirect?id=${this.$route.query.id}`, // 二维码内容
    //   // correctLevel: QRCode.CorrectLevel.L,
    //   // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
    //   // background: '#f0f'
    //   // foreground: '#ff0'
    // });
    this.getHasAddDeclare();
    this.getAccountInformation();
  },
  beforeDestroy() {
    removeEventListener("scroll", this.onScroll, false);
    this.set_tz_lx_hp({ zhengwentongzhi: "" });
  },
  computed: {
    // ...mapState("policy", ["zhengwentongzhi"]),
    ...mapState({
      zhengwentongzhi: state => state.policy.zhengwentongzhi,
      isVip: (state) => state.login.isVip
    }),
    getUserInfo() {
      return JSON.parse(window.sessionStorage.getItem("userinfo"));
    },
    getSubscribeImg() {
      const dingyue1img = require("../../../../assets/pc/images/policy/subscribe.png");
      const dingyue2img = require("../../../../assets/pc/images/policy/dingyue2.png");
      if (this.subscribe) return dingyue2img;
      return dingyue1img;
    },
    showVipCom () {
      let arr = [ '/formaldetails/jiedu', '/formaldetails/xinxi', '/formaldetails/mingdan' ];
      return this.isVip === '普通用户' && arr.includes(this.$route.path);
    }
  },
  methods: {
    ...mapMutations("policy", [
      "set_tz_lx_hp",
      "setTZZWShowPdf",
      "setHPMDShowPdf",
    ]),
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        if (res.data.length > 0) {
          this.userData = res.data[0];
        } else {
          this.userData = {};
        }
        return Promise.resolve();
      }
    },
    bindEntity () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.type === '0') {
            if (this.form.first_code === this.form.creditCode[14] && this.form.second_code === this.form.creditCode[15] && this.form.third_code === this.form.creditCode[16] && this.form.fourth_code === this.form.creditCode[17]) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请补全后四位');
            }
          } else {
            if (this.form.type === '1' && this.form.codeInput && this.form.codeInput.length === 18) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.codeInput);
            } else {
              this.$message.error('请输入18位社会信用代码');
            }
          }
        }
      });
    },
    async bindMember (area, estiblishTime, regCapital, legalPersonName, entityName, code) {
      const { data: res } = await request({
        method: 'post',
        url: '/pcp/PersonalCenter/newbindmemberone',
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1
        }
      });
      if (res.code !== 200) { return this.$message.error('主体名称或信用代码不正确'); }
      // this.hasBindEntity = true
      this.dialogVisible = !this.dialogVisible;
      this.shenbaoDialog = !this.shenbaoDialog;
      this.$message.success('绑定成功');
      const str = JSON.parse(localStorage.getItem('userinfo'));
      str.entityId = res.data.entityId;
      const str2 = JSON.stringify(str);
      localStorage.setItem('userinfo', str2);
      sessionStorage.setItem('userinfo', str2);
      this.getAccountInformation();
    },
    handleSelect (item) {
      this.form.code = item.creditCode.slice(0, 6) + '******';
      this.form.label = '请补全统一社会信用代码';
      this.form.type = '0';
      // for (let i in item) {
      //   if (this.form[i]) this.form[i] = item[i];
      // }
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    },
    querySearch (queryString, cb) {
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getentitynameandcode',
        params: {
          entityName: queryString
        }
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.items.forEach(v => {
            v.value = v.name;
          });
          let results = [];
          res.data.data.items.map(item => {
            // results.push(Object.assign({},{value:item.name}))
            results.push(item);
          });
          if (results.length === 0) {
            results = [ { value: '暂无数据' } ];
          }
          cb(results);
        }
      }).catch(err => { console.log(err); });
    },
    loadPageFunc() {
      if (this.$route.query.id) {
        window.sessionStorage.setItem("policyid", this.$route.query.id);
        this.getIdFromRoute = this.$route.query.id;
      } else {
        if (window.sessionStorage.getItem("policyid"))
          this.getIdFromRoute = window.sessionStorage.getItem("policyid");
      }
      this.getPolicyById(this.getIdFromRoute).then(() => {
        this.getSubscribe();
        this.addZCHistory();
        this.get_tz(this.getIdFromRoute).then(() => {
          this.get_lx_hp(this.getIdFromRoute).then(() => {
            this.loading.close();
          });
        });
      });
    },
    async getDeclareLog() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/PersonalCenter/getDeclareLog",
        data: {
          memberId: getMemberId(),
          pageNo: 1,
          pageSize: 10,
        },
      });
      const {
        code,
        data: { list },
      } = res;
      if (code !== 200) return this.$message.error("信息获取失败");
      if (list && list.length > 0) {
        const { entityName, contantName, contantPhone, remaks } = list[0];
        this.shenbaoForm = {
          ...this.shenbaoForm,
          entityName: entityName,
          contantName: contantName,
          contantPhone: contantPhone,
          // remark: remaks
        };
      } else {
        const userInfo = localStorage.getItem("userinfo");
        if (userInfo && JSON.parse(userInfo).entityId) {
          const { entityName } = JSON.parse(localStorage.getItem("userEntity"));
          const { mobile, nickName } = JSON.parse(userInfo);
          this.shenbaoForm = {
            ...this.shenbaoForm,
            entityName: entityName,
            contantName: nickName,
            contantPhone: mobile,
          };
        }
      }
      // return Promise.resolve()
    },
    async getHasAddDeclare() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/hasAddDeclare",
        params: {
          memberId: getMemberId(),
          fieldId: this.$route.query.id,
        },
      });
      if (res.code === 200) {
        if (res.data.isDeclared) {
        }
        const { isDeclared } = res.data;
        // this.declareObj = {
        //   disabled: isDeclared,
        //   title: isDeclared ? "申报中" : "我要申报",
        // };
      }
    },
    async addZCHistory() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "post",
          url: "/pcp/policyDetail/addZCHistory",
          params: {
            zid: this.getIdFromRoute,
            memberId: getMemberId(),
          },
        });
        return res;
      }
    },
    async getPolicyById(id) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/getPolicyById",
        params: {
          id,
        },
      });
      if (res.code !== 200) return;
      this.tongzhiTitleData = res.data;
      return Promise.resolve();
    },
    async get_tz(id) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/getDeclarationNotice",
        params: {
          id: id,
        },
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      if (res.data && res.data !== "") {
        const suffix = res.data.substr(
          res.data.lastIndexOf(".") + 1,
          res.data.length
        );
        if (this.imageType.indexOf(suffix) === -1) {
          this.setTZZWShowPdf(true);
        } else {
          this.setTZZWShowPdf(false);
        }
      }
      this.set_tz_lx_hp({ zhengwentongzhi: res.data });
    },
    async get_lx_hp(id) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/getReportNotice",
        params: {
          id: id,
        },
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      if (res.data.huopimingdan && res.data.huopimingdan !== "") {
        const suffix = res.data.huopimingdan.substr(
          res.data.huopimingdan.lastIndexOf(".") + 1,
          res.data.huopimingdan.length
        );
        if (this.imageType.indexOf(suffix) === -1) {
          this.setHPMDShowPdf(true);
        }
      }
      this.set_tz_lx_hp({
        huopimingdan: res.data.huopimingdan,
        contact: res.data.contact,
      });
    },
    onScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= this.top) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    handleRadio(data) {
      setInfoData(request).then((resLogin) => {
        if (this.preview) {
          this.$router.replace({
            path: data,
            query: {
              id: this.getIdFromRoute,
              preview: this.preview,
            },
          });
        } else {
          if (resLogin === "islogin") {
            this.$router.replace({
              path: data,
              query: {
                id: this.getIdFromRoute,
              },
            });
            // this.comName = data
          } else {
            if (
              data === "/formaldetails/tongzhi" ||
              data === "/formaldetails/wenji"
            ) {
              // if (data === 'TongZhi' || data === 'WenJi') {
              this.$router.replace({
                path: data,
                query: {
                  id: this.getIdFromRoute,
                },
              });
              // this.comName = data
              localStorage.setItem("radioButtonPath", data);
            } else {
              this.dialogVisiblePermission = !this.dialogVisiblePermission;
              this.typeDialog = "Login";
              this.radio1 = localStorage.getItem("radioButtonPath");
              // this.comName = this.radio1
              this.$router.replace({
                path: this.radio1,
                query: {
                  id: this.getIdFromRoute,
                },
              });
            }
          }
        }
      });
    },
    handleRoute() {
      this.radio1 = this.$route.path;
    },
    handleDeclare() {
      setInfoData(request).then((res) => {
        if (res === "islogin") {
          this.shenbaoDialog = true;
          this.dialogTitle = this.userData.name ? '我要申报' : '请先绑定主体';
        } else {
          this.shenbaoDialog = false;
          this.dialogVisiblePermission = !this.dialogVisiblePermission;
          this.typeDialog = "Login";
        }
      });
    },
    shenbaoEvent() {
      const _this = this;
      _this.disabledEvent = !_this.disabledEvent
      this.$refs.shenbaoFormRef.validate(async (valid) => {
        if (valid) {
          _this.shenbaoDialog = false;

          const { data: res } = await request(
            {
              method: "POST",
              url: "/pcp/policyDetail/declare",
              data: {
                entityName: _this.shenbaoForm.entityName,
                contantName: _this.shenbaoForm.contantName,
                contantPhone: _this.shenbaoForm.contantPhone,
                zhengceId: _this.$route.query.id,
                demandDescription: _this.shenbaoForm.remark,
                memberId: getMemberId(),
              },
            },
            "post"
          );

          if (res.code !== 200) return _this.$message.error("提交失败");
          // this.declareObj = {
          //   disabled: true,
          //   title: "申报中",
          // };
          _this.$message({
            message: "提交成功，平台客服稍后会与您联系",
            type: "success",
          });

        } else {
          _this.disabledEvent = !_this.disabledEvent
        }

      })
      if (!_this.shenbaoDialog) {
        setTimeout(() => {
          _this.disabledEvent = !_this.disabledEvent
        }, 500)
      }


    },
    async getSubscribe() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/policyDetail/subscribe",
          params: {
            zid: this.getIdFromRoute,
            mid: getMemberId(),
          },
        });
        if (res.code !== 200)
          return this.$message.error("订阅状态信息获取失败");
        this.subscribe = res.data === "0";
        // this.getSubscribeImg()
        // this.dingyue1img
      }
    },
    async setSubscribe() {
      setInfoData(request).then(async (res) => {
        if (res === "islogin") {
          this.subscribe = !this.subscribe;
          // this.subscribeImg = this.getSubscribeImg
          this.subscribeImg = this.dingyue1img;
          const { data: res } = await request({
            method: "POST",
            url: "/pcp/policyDetail/setSubscribe",
            data: {
              zid: this.getIdFromRoute,
              type: this.subscribe ? 0 : 1,
              mId: getMemberId(),
            },
          });
          if (res.code !== 200) {
            this.subscribe = !this.subscribe;
            // this.subscribeImg = this.getSubscribeImg
            this.subscribeImg = this.dingyue1img;
            return this.$message.error("操作失败");
          }
          if (this.subscribe) {
            this.subscribeVisible = true;
          } else {
            this.$message.success("操作成功");
          }
        } else {
          this.dialogVisiblePermission = !this.dialogVisiblePermission;
          this.typeDialog = "Login";
        }
      });
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      const creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        showClose: true,
        message: "已复制好，可贴粘。",
        type: "success",
      });
    },
    handleShare() {
      this.copyUrl(this.linkShare);
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission(type) {
      if (type === "Vip") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
      } else if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    },
    handleReturn() {
      // this.$router.replace({ path: fromPath })
      // this.$refs.routerCom.$refs.pdfView.clearIframe()
      localStorage.setItem("isDetailBack", true);
      this.$router.back();
      setTimeout(() => {
        if (this.$route.path.indexOf("formaldetails") !== -1)
          this.handleReturn();
      }, 100);
    },
    handleCopy() {
      this.copyUrl(this.tongzhiTitleData.title);
    },
    goToRecord () {
      this.$router.push({
        path: '/push/mysubscribe'
      });
    }
  },
  watch: {
    $route: "handleRoute",
    'form.name' (val) {
      if (!val) {
        this.form.code = '';
        this.form.codeInput = '';
        this.form.creditCode = '';
        this.form.type = '1';
        this.form.label = '统一社会信用代码';
      }
    },
    'form.first_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.second_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    'form.third_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.fourth_code' (val) {
      if (val === '') {
        this.$refs[`inputRef${3}`].focus();
      }
    }
  },
};
</script>

<style lang="less" scoped>
.copy-title {
  cursor: pointer;
}
.my-breadcrumb {
  margin-bottom: 30px;
}
.project-details {
  padding: 43px 0;
  background-color: #fff;
  .el-breadcrumb {
    // padding: 0 55px;
  }
  .zc {
    padding: 10px 0px;
    display: flex;
    background-color: #fff;
    // height: 68px;
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 10;
      box-shadow: 0 2px 10px #ebecef;
    }
    & > div:nth-child(1) {
      align-self: center;
      div {
        font-size: 30px;
        // width: 740px;
        padding-right: 55px;
      }
      .sticky-title {
        font-size: 20px;
        // width: 500px;
        padding: 30px 55px 30px 0;
        font-weight: 600;
      }
    }
    & > div:nth-child(2) {
      flex: 1;
      text-align: end;
      align-self: center;
    }
  }
  .content {
    // padding: 0 55px;
    .title-info {
      display: flex;
      font-size: 14px;
      color: #8d8d8d;
      padding-bottom: 18px;
      border-bottom: 2px solid #e5e5e5;
      div:nth-child(1) {
        flex: 1;
        span {
          margin-right: 30px;
        }
      }
      div:nth-child(2) {
        span {
          margin-left: 30px;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    .el-radio-group {
      margin: 30px 0 50px;
    }
    .modal{
      position: relative;
    }
  }
  .shengbao-dialog {
    /deep/.el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
      .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    /deep/.el-dialog__body {
      padding: 35px 40px;
      color: #000;
      .head {
        display: flex;
        img {
          margin-right: 10px;
        }
        div {
          color: #156ed0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
      .el-form {
        margin-top: 30px;
        .el-form-item {
          margin-bottom: 0;
          .el-form-item__content {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.el-input /deep/.el-form-item__error {
  padding-top: 0;
}
.el-form /deep/.el-form-item__label {
  height: 20px;
  line-height: 20px;
  margin-top: 30px;
}
.el-button--primary {
  background-color: #156ed0;
  border-color: #156ed0;
}
.el-button--primary:active,
.el-button--primary:focus,
.el-button--primary:hover {
  opacity: 0.8;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #fff;
  background-color: #a0cfff;
  border-color: #a0cfff;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #156ed0;
  border-color: #156ed0;
}
.share-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.4;
  }
}

@keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.4;
  }
}
.hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goto_bind {
  margin-right: 10px;
  width: 120px;
  height: 42px;
  border: 1px solid #156ED0;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #156ED0;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.form ::v-deep .el-form-item{
  width: 60%;
}
.credit_code{
  display: flex;
  .div_code{
    // width: 42px;
    margin-right: 5px;
    text-align:center;
    ::v-deep .el-input__inner{
      width: 40px;
      height: 30px;
      font-size: 12px;
    }
  }
}
:v-deep .el-input__inner{
  font-size: 16px;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.bind_btn_box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bind_btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border: none;
  width: 320px;
  height: 46px;
  background: #156ED0;
  border-radius: 5px;
  cursor: pointer;
}
.subscrib_dialog{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 26px;
  .img{
    width: 36px;
    height: 36px;
  }
  & > div:first-child{
    margin-bottom: 20px;
  }
  .el-button{
    margin-top: 20px;
  }
}
</style>
